<template>
  <el-container>
    <el-aside width="45%">
      <el-tabs value="first" style="outline: 0;">
        <el-tab-pane label="未关联用户" name="first">
          <el-table
              :data="userNotInGroup.tableData"
              :height="tableHeight"
              border
              size="small"
              style="width: 100%;margin-top: 10px;"
              v-loading="userNotInGroup.loading"
              ref="topictable"
          >
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="userName"
                label="用户名"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="userCode"
                label="用户代码"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="isLock"
                label="是否激活"
                :formatter="formatIsLock"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="email"
                label="邮箱"
                show-overflow-tooltip>
            </el-table-column>
          </el-table>

          <el-pagination
              @size-change="(val) => handleSizeChange('userNotInGroup',val)"
              @current-change="(val) => handleCurrentChange('userNotInGroup',val)"
              :current-page="userNotInGroup.currentPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="userNotInGroup.pageSize"
              :pager-count="5"
              layout="total, sizes, prev, pager, next, jumper"
              :total="userNotInGroup.pageTotal"
              style="margin-top: 10px">
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </el-aside>
    <div style="width: 10%;display: block;line-height: 500px;text-align: center">
      <el-button type="primary" @click="removeGroupUser" icon="el-icon-caret-left" size="small"></el-button>
      <el-button type="primary" @click="insertGroupUser" icon="el-icon-caret-right" size="small"></el-button>
    </div>
    <el-main width="45%" style="box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)">
      <el-tabs value="first" style="outline: 0;">
        <el-tab-pane label="已关联用户" name="first">
          <el-table
              :data="userInGroup.tableData"
              :height="tableHeight"
              border
              size="small"
              style="width: 100%;margin-top: 10px;"
              v-loading="userInGroup.loading"
              ref="topictable1"
          >
            <el-table-column
                type="selection"
                width="55">
            </el-table-column>
            <el-table-column
                prop="userName"
                label="用户名"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="userCode"
                label="用户代码"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="isLock"
                label="是否激活"
                :formatter="formatIsLock"
                show-overflow-tooltip>
            </el-table-column>
            <el-table-column
                prop="email"
                label="邮箱"
                show-overflow-tooltip>
            </el-table-column>
          </el-table>

          <el-pagination
              @size-change="(val) => handleSizeChange('userInGroup',val)"
              @current-change="(val) => handleCurrentChange('userInGroup',val)"
              :current-page="userInGroup.currentPage"
              :page-sizes="[10, 20, 50, 100]"
              :page-size="userInGroup.pageSize"
              layout="total, sizes, prev, pager, next, jumper"
              :total="userInGroup.pageTotal"
              style="margin-top: 10px">
          </el-pagination>
        </el-tab-pane>
      </el-tabs>
    </el-main>
  </el-container>

</template>

<script>
import request from "@/utils/request";

export default {
  components: {},
  props: {
    transfer: Object,
    findByPage: Function
  },
  data() {
    return {
      tableHeight: null,
      userInGroup: {
        tableData: [],
        multipleSelection: [],
        currentPage: 1,
        pageSize: 10,
        loading: true,
        pageTotal: 0,
      },
      userNotInGroup: {
        tableData: [],
        multipleSelection: [],
        currentPage: 1,
        pageSize: 10,
        loading: true,
        pageTotal: 0,
      },
      groupUserList: []
    }
  },
  methods: {
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    handleSizeChange(tableName, val) {
      if (tableName === 'userNotInGroup') {
        this.userNotInGroup.pageSize = val
        this.findUserNotInGroup()
      } else {
        this.userInGroup.pageSize = val
        this.findUserInGroup()
      }
    },
    handleCurrentChange(tableName, val) {
      if (tableName === 'userNotInGroup') {
        this.userNotInGroup.currentPage = val
        this.findUserNotInGroup()
      } else {
        this.userInGroup.currentPage = val
        this.findUserInGroup()
      }
    },
    formatIsLock: function (row) {
      return row.isLock === 1 ? '是' : row.isLock === 0 ? '否' : '未知'
    },
    findUserInGroup() {
      this.userInGroup.loading = true

      return request({
        url: `/masterdata/sysGroupUser/findUserGroup/1/${this.transfer.groupId}/${this.userInGroup.currentPage}/${this.userInGroup.pageSize}`,
        method: 'post',
        contentType: 'application/json',
        baseURL: 'http://127.0.0.1:8093',
        data: {}
      }).then((response) => {
        this.userInGroup.tableData = response.data.content;
        this.userInGroup.pageTotal = response.data.totalElements;
        this.userInGroup.loading = false;
      })
    },
    findUserNotInGroup() {
      this.userNotInGroup.loading = true

      return request({
        url: `/masterdata/sysGroupUser/findUserGroup/2/${this.transfer.groupId}/${this.userNotInGroup.currentPage}/${this.userNotInGroup.pageSize}`,
        method: 'post',
        contentType: 'application/json',
        baseURL: 'http://127.0.0.1:8093',
        data: {}
      }).then((response) => {
        this.userNotInGroup.tableData = response.data.content;
        this.userNotInGroup.pageTotal = response.data.totalElements;
        this.userNotInGroup.loading = false;
      })
    },
    insertGroupUser() {
      this.groupUserList = []

      const rows = this.$refs.topictable.store.states.selection

      rows.forEach(row => {
        this.groupUserList.push({'userId': row.id, 'groupId': this.transfer.groupId})
      })

      return request({
        url: `/masterdata/sysGroupUser/createSysGroupUser`,
        method: 'post',
        contentType: 'application/json',
        baseURL: 'http://127.0.0.1:8093',
        data: this.groupUserList
      }).then((response) => {
        if (response.code === 200) {
          this.$message({
            showClose: true,
            message: response.message,
            type: 'success',
          });

          this.findUserInGroup()
          this.findUserNotInGroup()
        }
      })
    },
    removeGroupUser() {
      this.groupUserList = []

      const rows = this.$refs.topictable1.store.states.selection

      rows.forEach(row => {
        this.groupUserList.push({'userId': row.id, 'groupId': this.transfer.groupId})
      })

      return request({
        url: `/masterdata/sysGroupUser/deleteSysGroupUser`,
        baseURL: 'http://127.0.0.1:8093',
        method: 'post',
        contentType: 'application/json',
        data: this.groupUserList
      }).then((response) => {
        if (response.code === 200) {
          this.$message({
            showClose: true,
            message: response.message,
            type: 'success',
          });

          this.findUserInGroup()
          this.findUserNotInGroup()
        }
      })
    }
  }, created() {
    this.findUserInGroup()
    this.findUserNotInGroup()
  }, mounted() {
    this.tableHeight =
        window.innerHeight - this.$refs.topictable.$el.offsetTop - 360;
  }
}
</script>

<style lang="scss" scoped>
.el-dialog__body {
  padding: 0 !important;
}

.el-aside {
  color: #333;
  padding: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, .12), 0 0 6px rgba(0, 0, 0, .04)
}

.el-main {
  color: #333;
  padding: 5px !important;
}

.el-container {
  /*padding: 20px;*/
  height: 100% !important;
}

.el-tree-node__content {
  height: 30px !important;
}
</style>
